/* ******************************* Variables *******************************/

/* Button Colors */
$call-to-action: #3287D3;
$secondary: #E1E1E1;
$hover: #fffae6;

/* Shades of Gray */
$dark: #15242e;
$medium-dark: #9E9E9E;
$medium: #E5E5E5;
$light: #F9F9F9;

/* Resmark Brand Colors */
$brand: #EB7B1E;
$brand-secondary: #1B1C1D;

$background: #F0F4F5;
$background-page: #FFFFFF;

$link: #4183C4;
$link-hover: #1E70BF;
$input-background: #F0F4F5;

/* Form Elements Colors */
$success: #5DAC58;
// Option 2 $success: $success;
$warning: #FFCC00;
$info: #f44336;
$error: #e80404;
$bg-form: #F0F4F5;
$teal: #00B5AD;

/* Z-index Variables */
$z-indexHigh: 10;
$z-indexLow: 1;

/* ******************************* Mixins *******************************/

@mixin box-shadow($h: 1px, $v: 1px, $b:4px, $brand-color: rgba(0,0,0,.25)) {
  -webkit-box-shadow: $h $v $b $brand-color;
  -moz-box-shadow: $h $v $b $brand-color;
  box-shadow: $h $v $b $brand-color;
}

@mixin text-shadow {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

@mixin circle {
  height: 380px;
  width: 380px;
  -moz-border-radius: 190px;
  -webkit-border-radius: 190px;
  margin: 0 auto;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

body {
  margin: 0;
  padding: 0;
  background: $background;
  min-width: auto;
  @media only screen and (max-width: 767px) {
    background-color: $background-page !important;
  }

}

.ui.header.signature {
  font-family: Arizonia, Helvetica, Arial, sans-serif !important;
  font-size: 220%;
  @media only screen and (max-width: 767px) {
    font-size: 170%;
  }
}

.initials {
  font-size: 1.2rem !important;
  color: inherit !important;
  cursor: pointer !important;
  background: none !important;
  border: none !important;
}

.ui.link {
  cursor: pointer;
}

.ui.button {
  text-transform: uppercase !important;
}

.not-display-checkbox {
  &::before, &::after {
    content: none !important;
  }
}

.field.ui.checkbox.invalid-checkbox > input[type=checkbox] {
    border: 1px solid #e80404;
    border-radius: 3px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    opacity: 1 !important;
}


.react-tel-input {
  width: 100%;

  input {
    padding: .67857143em 1em .67857143em 44px !important;
    height: auto !important;
  }

  .flag-dropdown {
    // padding-top: 5px;
    .selected-flag {
      height: 100%;
      z-index: 1;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    width: 100%;
  }
}

.hover {
  &:hover {
    background: $hover;
  }
}

//Header
.main-header {
  margin-bottom: 20px;
  background: #ffffff;
  .logo {
    width: 200px !important;
    height: auto !important;
  }
  .ui.header {
    //background-color: $dark;
    color: $brand;
    padding: 10px 0 0;
  }
  .ui.vertical.segment {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  }
}

#logo {
  max-height: 90px;
  @media only screen and (max-width: 767px) {
    max-height: 60px;
  }

}

//Footer
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 10px 5px;
  background-color: $dark;
  .logo {
    width: 200px !important;
    height: auto !important;
  }
  div {
    color: $medium;
  }
}

.footer-success-message.desktop {
  position: absolute !important;
  bottom: 70px;
  left: 50%;
  text-align: center;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 800px;
}

.disabled-link {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .footer-success-message.desktop {
    display: none;
  }
  .footer-success-message.mobile {
    position: initial !important;
    max-width: 800px;
    text-align: center;
    width: 100%;
    transform: translate(0, 0) !important;
  }
}
//Form
.ui.header.form-header {
  background: $input-background;
  color: rgba(0, 0, 0, .6);
  .ui.center.aligned.header{
    font-weight: 400;
    margin-bottom: .5rem;
  }
}

.ui.container {
  max-width: 800px !important;
}

////////////////// CLASSES TO HELP SEMANTIC LAYOUT A LITTLE BETTER (little tweaks) ////////////////////
.neg-margin-top {
  margin-top: -12px !important;
}

.neg-margin-bottom {
  margin-bottom: -12px !important;
}

.neg-margin-left {
  margin-left: -12px !important;
}

.neg-margin-right {
  margin-right: -12px !important;
}

.margin-top {
  margin-top: 20px !important;
}

.margin-bottom {
  margin-bottom: 20px !important;
}

.margin-left {
  margin-left: 12px !important;
}

.margin-none {
  margin: 0 !important;
}

.margin-right {
  margin-right: 12px !important;
}

.padding-top {
  padding-top: 12px !important;
}

.padding-bottom {
  padding-bottom: 12px !important;
}

.padding-left {
  padding-left: 12px !important;
}

.padding-right {
  padding-right: 12px !important;
}

.padding {
  padding: 12px !important;
}

.padding-none-top {
  padding-top: 0 !important;
}

.padding-none-right {
  padding-right: 0 !important;
}

.padding-none-bottom {
  padding-bottom: 0 !important;
}

.padding-none-left {
  padding-left: 0 !important;
}

.padding-none {
  padding: 0 !important;
}
.ui.message.half-padding {
  padding: .5rem 1.5rem;
}

.txt-right {
  text-align: right !important;
}

.txt-left {
  text-align: left !important;
}

.txt-center {
  text-align: center !important;
}

.width-fluid {
  display: block;
  width: 100%;
}

.ui.fluid.button {
  display: inline-block;
}

.flag-dropdown {
  display: none;
}

.react-tel-input input {
  padding-left: 1em !important;
}

.ui.secondary.button {
  color: #2185d0;
  background-color: transparent;
  border: 1px solid #2185d0;
  &:hover {
    background-color: #2185d0;
    color: #ffffff;
  }
}

i.huge.icon, i.huge.icons {
  text-align: left;
}

.ui.items:not(.unstackable) > .item {
  flex-direction: row;
  align-items: center;
}

////////////////// END CLASSES TO HELP SEMANTIC LAYOUT A LITTLE BETTER (little tweaks) ////////////////

.calendar.icon {
  float: right;
  position: relative;
  top: -27px;
  right: 10px;
}

//Multi Lingual//
.ui.labeled.icon.button {
  padding-left: 3.27143em !important;
}

.participant-box {
  min-height: 210px;
  background: $background !important;
  .icon {
    color: $dark;
  }
}

.language-box {
  background: $background !important;
  box-shadow: none;
}

.centeredButton {
  min-width: 250px;
}

// # of Participants Selector
#participantSelector {
  margin-bottom: 1rem !important;
  .qty {
    input {
      border-radius: 0 !important;
      border-color: #C9CACA !important;
      width: 60px;
      background: $background !important;
      min-width: 90px;
      text-align: center;
    }
  }
  .qtyminus {
    border-radius: 5px 0 0 5px !important;
    background: #ffffff !important;
    border-color: #C9CACA !important;
    padding: 1rem 1.5rem !important;
  }
  .qtyplus {
    border-radius: 0 5px 5px 0 !important;
    background: #ffffff !important;
    border-color: #C9CACA !important;
    padding: 1rem 1.5rem !important;
  }
}

.footer-padding {
  padding-bottom: 100px;
}

.ui.primary.button {
  margin: 0;
}

#special-message {
  display: flex;
  padding: 0;
  .ui.grid {
    margin: auto 0;
  }
  .special-button {
    display: flex;
    flex-direction: row;
    padding: 0;
  }
  &.ui.info.message {
    .initial {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 .28571429rem .28571429rem;
      background: #2185d0;
      color: #ffffff;
      border: none;
      margin-bottom: .5px;
      font-size: 1.1rem;
      min-height: 42px;
      width: 100%;
      label {
        padding: 0;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .accept,
    .decline {
      display: flex;
      height: 42px;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem !important;
      width: 100%
    }
    .accept {
      border-radius: 0 0 0 .28571429rem;
      background: #2185d0;
      color: #ffffff;
      border: none;
    }
    .decline {
      border-radius: 0 0 .28571429rem 0;
      background: #FFFFFF;
      color: #2185d0;
    }
  }
  &.ui.positive.message {
    .initial {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 0 .28571429rem .28571429rem 0;
      background: #2C662D;
      border: none;
      margin-bottom: .5px;
      font-size: 1.1rem;
      min-height: 70px;
      width: 100%;
      .header {
        font-family: 'Allura', cursive;
        color: #ffffff;
        opacity: 1;
        font-weight: 300;
        letter-spacing: 5px;
        margin-top: .2px;
      }
    }
    .accept[type="button"] {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 0 .28571429rem .28571429rem 0;
      background: #2C662D;
      color: #ffffff;
      border: none;
      margin-bottom: .5px !important;
      font-size: .85rem !important;
      text-transform: uppercase;
      margin-bottom: .5px;
      font-size: .85rem;
      min-height: 70px;
      width: 100%;
    }
  }
  &.ui.error.message {
    .accept[type="button"] {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 .28571429rem .28571429rem 0;
      background: #9F3A38;
      color: #ffffff;
      border: none;
      margin-top: .5px !important;
      font-size: .85rem !important;
      text-transform: uppercase;
      margin-bottom: .5px;
      font-size: .85rem;
      min-height: 70px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  #special-message {
    display: flex;
    padding: 0;
    .ui.stackable.grid > .row > .column, .ui.stackable.grid > .row > .wide.column{
      padding: 1rem !important;
      &.special-button {
        padding: 0 !important;
      }
    }
    .ui.grid {
      margin: auto 0;
    }
    .special-button {
      display: flex;
      flex-direction: column;
      padding: 0 !important;
      height: auto;
    }
    &.ui.info.message {
      .initial {
        display: flex;
        height: 44px;
        min-height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 .28571429rem .28571429rem;
        background: #2185d0;
        color: #ffffff;
        border: none;
        margin-bottom: .5px;
        font-size: .85rem;
        label {
          padding: 0;
          font-size: .85rem;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
      .accept {
        display: flex;
        height: 44px;
        min-height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        background: #2185d0;
        border: none;
        margin-bottom: .5px !important;
        font-size: .85rem !important;
      }
      .decline {
        height: 44px;
        min-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 .28571429rem .28571429rem;
        background: #FFFFFF;
        color: #2185d0;
        border: 1px solid #2185d0;
        margin-top: .5px !important;
        font-size: .85rem !important;
      }
    }
    &.ui.positive.message {
      .initial {
        display: flex;
        height: 44px;
        min-height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 .28571429rem .28571429rem;
        background: #2C662D;
        border: none;
        .header {
          font-family: 'Allura', cursive;
          color: #ffffff;
          opacity: 1;
          font-weight: 300;
          letter-spacing: 5px;
          margin-top: .2px;
        }
      }
      .accept[type="button"] {
        display: flex;
        height: 44px;
        min-height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 .28571429rem .28571429rem;
        background: #2C662D;
        color: #ffffff;
        border: none;
        margin-bottom: .5px !important;
        font-size: .85rem !important;
        text-transform: uppercase;
      }
    }
    &.ui.error.message {
      .accept[type="button"] {
        height: 44px;
        min-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 .28571429rem .28571429rem;
        background: #9F3A38;
        color: #ffffff;
        border: none;
        margin-top: .5px !important;
        font-size: .85rem !important;
        text-transform: uppercase;
      }
    }
  }
}

.participantImage {
  max-height: 120px;
}
@media only screen and (max-width: 767px) {
  .selectParticipant {
    padding: 1rem 0 !important;
  }
}
.radioButton {
  min-height: 65px;
  background: $background !important;
  @media only screen and (max-width: 767px) {
    min-height: 0;
  }
}

.centerList {
  text-align: center;
  list-style-position: inside;
  padding: 0;
  li {
    padding-bottom: 1rem;
    width: 70%;
    margin: 0 auto;
  }
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.unvisible {
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
}

.flex {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.selectBtn {
  margin: 20px 0 0 0 !important;
  width: 100%;
  border-radius: 0 0 3px 3px !important;
  text-align: center;
  height: 50px;
  background-color: #4883c3;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.fullHeight, .fullHeight div.field {
  height: 100%;
}

.pointer {
  cursor: pointer !important;
}

.selectedParticipant {
  border: 1px solid #6fa861 !important;
}

.selectedParticipant .selectBtn {
  background-color: #6fa861;
}

.smallText {
  font-weight: 400 !important;
}

label.flex img {
  padding-bottom: 5px;
}

label.pointer.smallText {
  height: 10px;
}

.participantCard {
  height: 250px !important;
}

.flexColumn {
  display: flex !important;
  height: 100%;
  margin: 0 !important;
  width: 100%;
}
.flex .flexColumn .pointer {
  padding-top: 10px;
}

@media only screen and (max-width: 769px) {
  .participantCard {
    height: 220px !important;
    justify-content: center;
  }
}
