.textarea {
	display: inline-block;
	min-height: 12em;
	width: 100%;
	-moz-box-sizing: border-box;
	background: $input-background;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: .3125em;
	box-shadow: 0 0 rgba(0, 0, 0, .3) inset;
	color: rgba(0,0,0,.87);
	font-size: 1em;
	margin: 0;
	outline: medium none;
	padding: .65em 1em;
	transition: background-color .3s ease-out 0s, box-shadow .2s ease 0s, border-color .2s ease 0s;
	text-align: left;
	vertical-align: top;
}

#labelMargin {
	margin-top: 1em !important;
}

select.ui.dropdown,
div.ui.dropdown.selection,
.ui.form input:not([type]), .ui.form input[type=text], .ui.form input[type=email], .ui.form input[type=search], .ui.form input[type=password], .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=tel], .ui.form input[type=time], .ui.form input[type=url], .ui.form input[type=number] {
	background: $input-background !important;
}

.dropdown, .ui.form .inline.field > input,
.ui.form .inline.fields .field > input,
.ui.form textarea,
.ui.textarea {
	width: 100%;
	//max-width: 550px;
}
.ui.form .fields > .field {
	padding-left: 0;
	padding-right: 0;
}

.ui.form textarea, .ui.textarea {
	// width: 96%;
	display: inline-block;
	height: auto;
	background: $input-background;
	// max-width: 550px;
	//min-height: 7em;
}

//Error Messaging

.ui.basic.red.label {
	border: none;
	margin-top: 0 !important;
}
.ui.form .field .prompt.label {
	background: transparent !important;
	border: none !important;
	margin: 0 !important;
	font-size: 14px !important;
	font-weight: normal !important;
	color: $error !important;

	&:before {
		content: none !important;
	}
}

.ui.form .field.error label {
	color: inherit !important;
}

.ui.form .field.error input:not([type]), .ui.form .field.error input[type=date], .ui.form .field.error input[type=url], .ui.form .field.error input[type=datetime-local], .ui.form .field.error input[type=email], .ui.form .field.error input[type=number], .ui.form .field.error input[type=password], .ui.form .field.error input[type=search], .ui.form .field.error input[type=tel], .ui.form .field.error input[type=time], .ui.form .field.error input[type=text], .ui.form .field.error input[type=file], .ui.form .field.error select, .ui.form .field.error textarea, .ui.form .fields.error .field input:not([type]), .ui.form .fields.error .field input[type=date], .ui.form .fields.error .field input[type=url], .ui.form .fields.error .field input[type=datetime-local], .ui.form .fields.error .field input[type=email], .ui.form .fields.error .field input[type=number], .ui.form .fields.error .field input[type=password], .ui.form .fields.error .field input[type=search], .ui.form .fields.error .field input[type=tel], .ui.form .fields.error .field input[type=time], .ui.form .fields.error .field input[type=text], .ui.form .fields.error .field input[type=file], .ui.form .fields.error .field select, .ui.form .fields.error .field textarea {
	border-color: $error !important;
	color: $error !important;
}

.ui.pointing.label {
	&:before {
		content: none;
	}
}

.margin-segment {
	margin: 2rem 3rem 2rem 2rem;
}

.description {
	display: block;
	font-weight: 400;
	padding: 0;
	margin: -7px 0 7px 0;
	line-height: 1.2em;
	color: rgba(0,0,0,.6);
}

.fieldContainer {
	display: flex;
	flex-direction: row;
	margin: 3px 0 3px 0;
	padding: 0;
}

.fieldLabel {
	display: flex;
	flex-direction: row;
	margin: 0 10px 0 0;
	padding: 10px 0 10px 0;
	justify-content: flex-end;
    text-align: end;
    width: 150px;
}

.fieldContent {
	background-color: #F0F4F5;
	margin: 0 10px 0 0;
	font-weight: 700;
	padding: 10px 0 10px 10px;
    width: 100%;
}

@media only screen and (max-width: 769px) {
	.fieldContainer {
		display: flex;
		flex-direction: column;
		margin: 3px 0 3px 0;
		padding: 0;
        width: 100%;
	}
	.fieldLabel {
		display: flex;
		flex-direction: row;
		margin: 0 10px 0 0;
		padding: 10px 0 10px 0;
		justify-content: flex-start;
        text-align: start;
        width: 150px;
    }
}