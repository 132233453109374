@media only screen and (max-width: 767px) {
  .ui.very.padded.segment{
    padding: 1.5em !important;
    height: 100%;
  }
  .ui.fluid.button {
    margin-bottom: 1em;
  }
  .ui.button {
    width: 100%;
    margin-bottom: .5rem;
    font-size: 1.14285714rem;
    font-weight: 400;
  }
  .ui.stackable.grid > .row > .column, .ui.stackable.grid > .row > .wide.column {
    padding: 1rem 0 !important;
  }
  .eleven.wide.field {
    margin-top: 0.5rem !important;
  }
  .ui.items {
    margin: .5em 0;
  }
  .ui.items:not(.unstackable) > .item {
    margin: 1em 0 0;
  }
  .ui.checkbox{
    width: 100%;
  }
  .ui.modal .actions > .button {
    margin-left: 0;
  }
  .ui.compact.button{
    padding: .78571429em 1.5em;
  }
  .ui.mini.button{
    font-size: 1rem;
    margin-bottom: 0;
  }
  .ui.grid>.row>[class*="right aligned"].column.column{
    text-align: left;
  }
  .ui.stackable.grid>.row>.column, .ui.stackable.grid>.row>.wide.column {
    padding: .5rem 0!important;
  }
  .ui.form .field > label{
    font-size: 1rem;
    margin: 0 0 .5rem 0;
  }
  .ui.input{
    height: 43px;
  }
  .ui.input,
  .default.text,
  .ui.dropdown .text,
  div.field input{
    font-size: 16px !important;
  }
  //#document-signing{
  //  .ui.segment {
  //    padding: 0;
  //  }
  //}
  .ui.message p{
    opacity: 1;
  }
  .ui.stackable.grid > .column:not(.row){
    padding: .5rem 0 !important;
  }
}
button{
  margin-bottom: 1rem !important;
}
.ui.form .warning.message{
  display: block;
}
.ui.basic.primary.button{
  box-shadow: none !important;
  &:hover{
    box-shadow: none !important;
  }
}
.ui.text.container {
  width: 550px;
}

.ui.basic.primary.button{
  text-transform: capitalize !important;
  padding-right: 0;
}
h4.ui.header{
  .sub.header {
    font-size: .9rem;
  }
}

.ui.segment {
  box-shadow: none;
}
h1.ui.header{
  line-height: 2.2rem;
}
.ui.primary.button {
  font-weight: 400;
  font-size: 1rem !important;
  padding: 1rem 1.5rem;
}
.ui.secondary.button {
  font-weight: 400;
  font-size: 1rem !important;
  padding: 1rem 1.5rem;
}
.ui.big.button {
  font-size: 1.15rem !important;
}

